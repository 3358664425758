import { createMemo, For, Show, createEffect, Suspense } from "solid-js";
import { createAsync, type RouteDefinition } from "@solidjs/router";
import { useAccountProfile } from "~/services/account";
import { AccountPanel } from "~/components/account";
import type { AccountAddress } from "~/services/roma-api/account/types";
import { formatPhoneNumber } from "~/utils/helpers";

export const route = {
  preload: () =>
    useAccountProfile(
      { bps: true, communications: true },
      { cleanShipTos: true }
    ),
} satisfies RouteDefinition;

export default function Profile() {
  const details = createAsync(() =>
    useAccountProfile(
      { bps: true, communications: true },
      { cleanShipTos: true }
    )
  );
  const shipping = createMemo(() => {
    let shipping: [AccountAddress | null, [] | AccountAddress[]] = [null, []];
    if (details()?.ShipTos && details()?.ShipTos.length != 0) {
      shipping[0] = details()!.ShipTos[0];
      shipping[1] = details()!.ShipTos.slice(1);
    }
    return shipping;
  });

  return (
    <Suspense>
      <AccountPanel class="pb-20">
        <h2 class="text-3xl font-bold">My Profile</h2>
        <div class="divide-y pt-4 child:py-8">
          <section class="flex flex-col gap-6 ">
            <h3 class="text-base font-bold">Account Holder</h3>
            <div>
              <h4 class="text-sm font-medium text-gray-500">
                Account Details (#{details()?.AccountID})
              </h4>
              <div class="text-base pt-2">
                <p>
                  {details()?.FirstName} {details()?.LastName}
                </p>
                <p>{details()?.Phone}</p>
                <p>{details()?.Email.toLowerCase()}</p>
              </div>
            </div>
            <div>
              <h4 class="text-sm font-medium text-gray-500">
                Account Holder Address
              </h4>
              <div class="text-base pt-2">
                <p>{details()?.Company.Street}</p>
                <p>
                  {details()?.Company.City}, {details()?.Company.Region}{" "}
                  {details()?.Company.Postal} {details()?.Company.Country}{" "}
                </p>
              </div>
            </div>
            <a
              href="mailto:info@romamoulding.com"
              class="block text-xs text-roma-blue font-medium"
            >
              Request a change
            </a>
            <p class="bg-roma-grey p-5 text-xs text-roma-dark-grey">
              “Account Holder” is the individual who is authorized to make
              payments and receive invoices and statements of accounts. Changes
              to this information must be authorized by our Accounts Receivable
              team and may take 24 business hours to update.
            </p>
          </section>
          <section class="flex flex-col gap-6">
            <h3 class="text-base font-bold">Shipping</h3>
            <Show when={shipping()[0]} keyed>
              {(shipTo) => (
                <div>
                  <h4 class="text-sm font-medium text-gray-500">
                    Default Address
                  </h4>
                  <div class="text-base pt-2">
                    <p>{shipTo.Street}</p>
                    <p>
                      {shipTo.City}, {shipTo.Region} {shipTo.Postal}{" "}
                      {shipTo.Country}{" "}
                    </p>
                  </div>
                </div>
              )}
            </Show>
            <div class="flex flex-wrap">
              <For each={shipping()[1]}>
                {(ship, i) => (
                  <div class="w-full md:w-1/2 py-3">
                    <h4 class="text-sm text-gray-500 font-medium ">
                      Address {i() + 1}
                    </h4>
                    <div class="text-base pt-2">
                      <p>{ship.Street}</p>
                      <p>
                        {ship.City}, {ship.Region} {ship.Postal} {ship.Country}{" "}
                      </p>
                    </div>
                  </div>
                )}
              </For>
            </div>
            <a
              href="mailto:info@romamoulding.com"
              class="block text-xs text-roma-blue font-medium"
            >
              Request a change
            </a>
            <p class="bg-roma-grey p-5 text-xs text-roma-dark-grey">
              Contact us to add, edit or remove an address, or to switch the
              Default Address.
            </p>
          </section>
          <Show when={details()?.Representative} keyed>
            {(rep) => (
              <section class="flex flex-col gap-4">
                <h3 class="text-base font-bold">Your Sales Representative</h3>
                <div>
                  <h4 class="text-sm font-medium ">Name</h4>
                  <p class="text-base pt-2">{rep.Name}</p>
                </div>
                <div>
                  <h4 class="text-sm font-medium">Email</h4>
                  <Show
                    fallback={
                      <a
                        href="mailto:info@romamoulding.com"
                        class="text-roma-blue text-base"
                      >
                        info@romamoulding.com
                      </a>
                    }
                    when={rep.Email != ""}
                  >
                    <a
                      href={`mailto:${rep.Email}`}
                      class="text-roma-blue text-base"
                    >
                      {rep.Email}
                    </a>
                  </Show>
                </div>
                <div>
                  <h4 class="text-sm font-medium ">Telephone</h4>
                  <a href={`tel:${rep.Phone}`} class="text-roma-blue text-base">
                    {formatPhoneNumber(rep.Phone)}
                  </a>
                </div>
              </section>
            )}
          </Show>
        </div>
      </AccountPanel>
    </Suspense>
  );
}
